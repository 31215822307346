export const routes = [
    {
        path: '/task_status',
        name: 'task_status.browse',
        component: () => import(/* webpackChunkName: "TaskStatusBrowse" */ '@/views/app/TaskStatusBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/task_status/create',
        name: 'task_status.create',
        component: () => import(/* webpackChunkName: "TaskStatusActions" */ '@/views/app/TaskStatusActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/task_status/:id/edit',
        name: 'task_status.edit',
        component: () => import(/* webpackChunkName: "TaskStatusActions" */ '@/views/app/TaskStatusActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/task_status/:id/delete',
        name: 'task_status.delete',
        component: () => import(/* webpackChunkName: "TaskStatusActions" */ '@/views/app/TaskStatusActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]